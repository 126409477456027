@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro&family=Work+Sans:wght@300..700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* @import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght400&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap'); */
/* https://mui.com/material-ui/customization/typography/ */
/* https://stackoverflow.com/questions/48319372/changing-font-family-of-all-mui-components */

body{
  font-family: 'Source Sans Pro', sans-serif;
  font-family: 'Work Sans', sans-serif;

}

.fnt {
  font-family: 'Source Sans Pro', sans-serif;
  font-family: 'Work Sans', sans-serif;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.fw-700 {
  font-weight: 800;
  font-stretch: expanded;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.click-able {
  cursor: pointer;
}

.styles_bg_patern1__2puyE{
  background: repeating-linear-gradient(90deg, #000000 0, #000000 5%, transparent 0, transparent 50%), repeating-linear-gradient(180deg, #000000 0, #000000 5%, transparent 0, transparent 50%);
        background-size: 3em 3em;
        background-color: #ffffff;
        /* opacity: 0.15; */
}

.styles_bg_patern2__29W-O {
  background-color: #050005;
background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='200' height='200' viewBox='0 0 800 800'%3E%3Cg fill='none' stroke='%2308086E' stroke-width='2.2'%3E%3Cpath d='M769 229L1037 260.9M927 880L731 737 520 660 309 538 40 599 295 764 126.5 879.5 40 599-197 493 102 382-31 229 126.5 79.5-69-63'/%3E%3Cpath d='M-31 229L237 261 390 382 603 493 308.5 537.5 101.5 381.5M370 905L295 764'/%3E%3Cpath d='M520 660L578 842 731 737 840 599 603 493 520 660 295 764 309 538 390 382 539 269 769 229 577.5 41.5 370 105 295 -36 126.5 79.5 237 261 102 382 40 599 -69 737 127 880'/%3E%3Cpath d='M520-140L578.5 42.5 731-63M603 493L539 269 237 261 370 105M902 382L539 269M390 382L102 382'/%3E%3Cpath d='M-222 42L126.5 79.5 370 105 539 269 577.5 41.5 927 80 769 229 902 382 603 493 731 737M295-36L577.5 41.5M578 842L295 764M40-201L127 80M102 382L-261 269'/%3E%3C/g%3E%3Cg fill='%23555254'%3E%3Ccircle cx='769' cy='229' r='5'/%3E%3Ccircle cx='539' cy='269' r='5'/%3E%3Ccircle cx='603' cy='493' r='5'/%3E%3Ccircle cx='731' cy='737' r='5'/%3E%3Ccircle cx='520' cy='660' r='5'/%3E%3Ccircle cx='309' cy='538' r='5'/%3E%3Ccircle cx='295' cy='764' r='5'/%3E%3Ccircle cx='40' cy='599' r='5'/%3E%3Ccircle cx='102' cy='382' r='5'/%3E%3Ccircle cx='127' cy='80' r='5'/%3E%3Ccircle cx='370' cy='105' r='5'/%3E%3Ccircle cx='578' cy='42' r='5'/%3E%3Ccircle cx='237' cy='261' r='5'/%3E%3Ccircle cx='390' cy='382' r='5'/%3E%3C/g%3E%3C/svg%3E");
}

.styles_bg_patern3__qo751 {
  background-image: url(data:image/png;base64,UklGRggEAABXRUJQVlA4TPsDAAAvL8ALAHcgkE12j/xVU1iO5Zj/7DCgLQBgm8Z5vPEB63WZzO6SculuIxDdGYxLF44tWyZdGQZBd43ASacRsmVfFw0kTnfLcvLa3T7f+oL+x/6EiP4/QqLV8mwMVEZdnNjYHuIPZ5S3tLv8i8eE7620oV3XDBpjDZx1JkQAsmBgVRsJwne63fFsxSLmRKXYtjygI92oKbSoZbCTCcdHpQcgP003Ci6vF+JcGqQcQFMCfQD3Nom/cYKihJlWSBeLeHOQMTdVVnG3zLhj0IcQ5IPPg+j2Z/9s5fzxJsqPIqnDEf5yfpIeaFSWciZBL21UdHxYnzCJeyJcfXwXp/fFiCNJTDo1HC8O5zFkaXwEo0UlBEHrTfdAF5Zj7mNDGqCWYFIT7cI1qBuXPQ7DUq74zVrg9auLL7oC0czjdpWQ+PfY7fDz7Xq1jPUuurzlnqxFHBcl3r3+swcuEJ6l89IWf3jz+wd5sEuaOa1Ss99Um++4F1MjT0tAN2IpMbk/s4b88UAdD/cf3NtMh/kexlmVO/4fscLWgvDSNYBFxBcT0LR/VaRymDLY1JdFzZh9CxfV7EkelkI9th4ibyXL7LpQ59qbZHVpvKna0UH6rQ1XDeOfPTnE/A0YgOxDD0J/hA/7cDlFn7aWRH0zpeNbTnoJkzIszi+S+wmZTdL1mt0rJ6FXZ3aY3G6YaCC5eE//DXCCvBJ4H4UMK+whgPCwHqOeUGSmG5evTfIAaJlz7d1SS0D5L7uYU8TUNb4XFjcv9gwo0nkl06AYJ6qEbFhJj7vlnWTUr+68mBSIdh+vy8RIhrAVtyUSfFC5rJeX36yL80KAkaI2NGgH82EETNGjDcANVhP+J8d4nm4UkmhkIJYvVxuuNROaVVrpCCFAXHFbQWmu4BsZZ+neayKZjj3Y6HKRWYvzpDtmYWK7E2z5sMR62fG9ekYG25thpwe2+V7nh3jFNrNm6UUL4Dp+nAeJZUOhFhRY7vD3QyEx5MzxiSivlMPZAK7g3fTnRzCyQ2e2UUUDOYm/1H9zgB2VhbI5El4RfoeGeH+6jklllKTWpZ8M2MYVwZa6WTODX5Yx/4VKhZy6KubiXG3oSp7nPWxNim2dr0AU+IgxGY8e0RLMjWBKmPszEvKVQE29s6t5eLHIx7PYIuKMKrpVH4uSYh5UCsAj2G6d6u7Y2/IscmnUBjA/LJ9bu94RIZTX3xB6rCl4Pjm3xlxfSOsCYwvdfsHvFGdaXsPQMt8FQl9y/k82gjx5Cte+I8M0I59Wc0B4rGweAxWZZQp2cFwAJ8LnuPlTWbtDQJmvxu2HW3xrpOQH9PDX+biHIjKIGYbWeN3J9wcQvP/BlXIDMk7zbrYZdC+pCQA=);
  background-repeat: repeat;
}

.styles_countUp__1ioFV {
  /* font-size: larger; */
  font-size: 40px;
  font-weight: 900;
}
body{
  font-family: 'Source Sans Pro', sans-serif;
}

